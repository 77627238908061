body .joe_detail {
  &__article {
    padding-top: 15px;
    color: var(--prose-color);
    color: var(--prose-color);
    padding-top: 15px;
    word-break: break-all;
    margin-bottom: 60px;
  }
  &__copyright {
    .content {
      .item {
        svg {
          display: inline-block;
        }
        .text {
          word-break: break-all;
        }
      }
    }
  }
}

@media (max-width: 419px) {
  body .joe_detail {
    margin-left: -15px;
    margin-right: -15px;
    &__category {
      .item {
        font-size: 10px;
        padding: 3px 8px;
        border-radius: 0.25rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 8rem;
        transition: transform 0.35s, opacity 0.35s;
        line-height: 1;
        &:hover {
          opacity: 0.85;
          transform: translate3d(0, -3px, 0);
        }
        & + .item {
          margin-left: 0.5rem;
        }
      }
    }
    .prose {
      img, .prose-code, .table-wrapper, iframe {
        margin-left: -15px;
        margin-right: -15px;
        width: calc(100% + 30px);
      }
      img, .table-wrapper {
        max-width: calc(100% + 30px);
      }
    }
  }
}
