
:root {
  --color-white: #ffffff;
  --color-black: #0c0c0d;

  --color-primary-50: #d9e5ff;
  --color-primary-100: #b3cbff;
  --color-primary-200: #8db0ff;
  --color-primary-300: #6696ff;
  --color-primary-400: #407cff;
  --color-primary-500: #1a62ff;
  --color-primary-600: #0047e1;
  --color-primary-700: #0035a9;
  --color-primary-800: #002370;
  --color-primary-900: #001238;
  --color-secondary-50: var(--color-gray-50);
  --color-secondary-100: var(--color-gray-100);
  --color-secondary-200: var(--color-gray-200);
  --color-secondary-300: var(--color-gray-300);
  --color-secondary-400: var(--color-gray-400);
  --color-secondary-500: var(--color-gray-500);
  --color-secondary-600: var(--color-gray-600);
  --color-secondary-700: var(--color-gray-700);
  --color-secondary-800: var(--color-gray-800);
  --color-secondary-900: var(--color-gray-900);
  --color-gray-50: #fafafa;
  --color-gray-100: #f4f4f5;
  --color-gray-200: #e4e4e7;
  --color-gray-300: #D4d4d8;
  --color-gray-400: #a1a1aa;
  --color-gray-500: #71717A;
  --color-gray-600: #52525B;
  --color-gray-700: #3f3f46;
  --color-gray-800: #27272A;
  --color-gray-900: #18181B;
  --radii-sm: 0.375rem;
  --radii-md: 0.5rem;
  --radii-lg: 0.75rem;
  --fontSize-xs-value: 0.75rem;
  --fontSize-sm-value: 0.875rem;
  --fontSize-base-value: 1rem;
  --fontSize-lg-value: 1.125rem;
  --fontSize-xl-value: 1.25rem;
  --lead-none-value: 1;
  --lead-tight-value: 1.25;
  --lead-snug-value: 1.375;
  --lead-normal-value: 1.5;
  --lead-relaxed-value: 1.625;
  --lead-loose-value: 2;
  --font-sans: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  --font-serif: ui-serif, Georgia, Cambria, Times New Roman, Times, serif;
  --font-mono: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  
  --typography-verticalMargin-sm: 16px;
  --typography-verticalMargin-base: 24px;
  --typography-letterSpacing-tight: -0.025em;
  --typography-letterSpacing-wide: 0.025em;
  --typography-fontSize-xs: 12px;
  --typography-fontSize-sm: 14px;
  --typography-fontSize-base: 16px;
  --typography-fontSize-lg: 18px;
  --typography-fontSize-xl: 20px;
  --typography-fontSize-2xl: 24px;
  --typography-fontSize-3xl: 30px;
  --typography-fontSize-4xl: 36px;
  --typography-fontSize-5xl: 48px;
  --typography-fontSize-6xl: 60px;
  --typography-fontSize-7xl: 72px;
  --typography-fontSize-8xl: 96px;
  --typography-fontSize-9xl: 128px;
  --typography-fontWeight-thin: 100;
  --typography-fontWeight-extralight: 200;
  --typography-fontWeight-light: 300;
  --typography-fontWeight-normal: 400;
  --typography-fontWeight-medium: 500;
  --typography-fontWeight-semibold: 600;
  --typography-fontWeight-bold: 700;
  --typography-fontWeight-extrabold: 800;
  --typography-fontWeight-black: 900;
  --typography-lead-none: 1;
  --typography-lead-tight: 1.25;
  --typography-lead-snug: 1.375;
  --typography-lead-normal: 1.5;
  --typography-lead-relaxed: 1.625;
  --typography-lead-loose: 2;
  --typography-font-display: var(--font-sans);
  --typography-font-body: var(--font-sans);
  --typography-font-code: var(--font-mono);
  --typography-color-primary-50: var(--color-primary-50);
  --typography-color-primary-100: var(--color-primary-100);
  --typography-color-primary-200: var(--color-primary-200);
  --typography-color-primary-300: var(--color-primary-300);
  --typography-color-primary-400: var(--color-primary-400);
  --typography-color-primary-500: var(--color-primary-500);
  --typography-color-primary-600: var(--color-primary-600);
  --typography-color-primary-700: var(--color-primary-700);
  --typography-color-primary-800: var(--color-primary-800);
  --typography-color-primary-900: var(--color-primary-900);
  --typography-color-secondary-50: var(--color-secondary-50);
  --typography-color-secondary-100: var(--color-secondary-100);
  --typography-color-secondary-200: var(--color-secondary-200);
  --typography-color-secondary-300: var(--color-secondary-300);
  --typography-color-secondary-400: var(--color-secondary-400);
  --typography-color-secondary-500: var(--color-secondary-500);
  --typography-color-secondary-600: var(--color-secondary-600);
  --typography-color-secondary-700: var(--color-secondary-700);
  --typography-color-secondary-800: var(--color-secondary-800);
  --typography-color-secondary-900: var(--color-secondary-900);
}
